import React from "react";

import { Route, BrowserRouter as Router, withRouter } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";

import MTDOptions from "./MTDoptions";
import MTDOptionsPage2 from "./MTDoptionsPage2";
import SignupForm from "./signupForm";


const Routes = (props) => {
  
  return (
    <HttpsRedirect>
        <Router>
          <div className="App">
            <Route exact path="/" component={MTDOptions} />
            <Route path="/page2" component={MTDOptionsPage2} />
            <Route path="/form" component ={SignupForm} />
          </div>
        </Router>
     </HttpsRedirect>
  );
};

export default withRouter(Routes);
