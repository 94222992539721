
import React, { useState, useEffect } from "react";

import { IonButton, IonCheckbox, IonAlert, IonGrid, IonRow, IonCol, IonRadioGroup, IonRadio, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption } from '@ionic/react';


import "./App.css";

export const SignupForm = (props) => {

    const storedState = props.location.state ? props.location.state : {}
    const [firstName, setFirstName] = useState("")
    const [secondName, setSecondName] = useState("")
    const [email, setEmail] = useState("")
    const [iAm, setIAm] = useState("individual")
    const [submitted, setSubmitted] = useState(false)
    const [alertOpen, showAlert] = useState(false)
    const [alertTitle, setAlertTitle] = useState("")
    const [alertMessage, setAlertMessage] = useState("")

    const api_url = 'api.untied.io';

    const validateEmail = (email) => {
        if (email == ""){ 
          return false}
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const sendForm = () => {

        if (!validateEmail(email)) {
            setAlertTitle("Missing email")
            setAlertMessage("Please enter your email address")
            showAlert(true)
        }

        fetch(`https://${api_url}/hubspot/addMTDContact.php`, {
            method: "POST",            
            body: JSON.stringify({ email, firstname: firstName, surname: secondName, i_am_an: iAm, questions: storedState})
        })
            .then(response => response.json())
            .then(json => {
                console.log(json)
                setSubmitted(true);
            });

    }


    const displayMain = () => {

        let formType
        if(storedState.form){
        formType = storedState.form
        }
        console.log(formType)
        if(formType !== ""){
            return(
            <div style = {{
                width: "fit-content",
                margin: "auto",
                marginTop: 50,
                // backgroundColor: "rgba(132,200,217, 0.1)",
                // border: "2px solid rgba(132,200,217, 1)",
                borderRadius: "5px",
                paddingBottom:20
             }}>
                <IonGrid fixed={true} style={{ "--ion-grid-width-md": '600px', "--ion-grid-width-lg": '600px', "--ion-grid-width-xl": '600px' }}>
                {formType == "success" && <h2 style = {{textAlign: "left", color: "#3f90b0", marginLeft: 5}}>Sign up for MTD income tax pilot</h2>}
                {formType == "mailingList" && <h2 style = {{textAlign: "left", color: "#3f90b0", marginLeft: 5}}>Sign up for the mailing list</h2>}

                    {submitted ? <p style={{textAlign:'left', marginLeft:5}}>Thanks for signing up, someone will be in touch with you soon.</p> 
                               : <>

                                    <IonRow>
                                    <IonCol>
                                    <IonItem className="input-container" lines = "none">
                                            <IonInput 
                                                lines = "none"
                                                value={firstName}
                                                onIonChange={e => setFirstName(e.detail.value)}
                                                placeholder="First name" 
                                                inputmode="text" 
                                                type="text" 

                                                />
                                    </IonItem >
                                    </IonCol>

                                    </IonRow>
                                    <IonRow>
                                    <IonCol>
                                            <IonItem className="input-container" lines = "none">
                                            <IonInput 
                                                lines = "none"
                                                value={secondName}
                                                onIonChange={e => setSecondName(e.detail.value)}
                                                placeholder="Second name" 
                                                inputmode="text" 
                                                type="text" 
                                                    
                                                />
                                    </IonItem >
                                    </IonCol>
                                    </IonRow>
                                    <IonRow>
                                    <IonCol>
                                        <IonItem className="input-container" lines = "none">
                                            <IonInput 
                                                lines = "none"
                                                value={email}
                                                onIonChange={e => setEmail(e.detail.value)}
                                                placeholder="Email" 
                                                inputmode="text" 
                                                type="text" 
                                                    
                                                />
                                    </IonItem >
                                    </IonCol>
                                    </IonRow>
                                    <IonRow>
                                    <IonCol style = {{textAlign: "right"}}>
                                        <IonItem className="input-container" lines = "none">
                                            <IonLabel position="floating">I am an</IonLabel>
                                                                <IonSelect value={iAm} interface="popover"
                                                                style = {{width: "100%"}}
                                                                lines = "none"
                                                                onIonChange={e => setIAm(e.detail.value)}>
                                                                
                                                                    <IonSelectOption value="individual">Individual</IonSelectOption>
                                                                    <IonSelectOption value="agent">Agent</IonSelectOption>
                                                                </IonSelect>
                                                            </IonItem >
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonButton 
                                            className = "ion-button-money"
                                            onClick = {()=> {
                                                if (formType == "success"){
                                                    let totalState = {
                                                        ...storedState,
                                                        firstName,
                                                        secondName,
                                                        email,
                                                        iAm
                                                    }
                                                    //console.log(totalState)
                                                }
                                                if (formType == "mailingList"){
                                                    let totalState = {
                                                        ...storedState,
                                                        firstName,
                                                        secondName,
                                                        email,
                                                        iAm
                                                    }
                                                    //console.log(totalState)

                                                }

                                                sendForm()
                                            }}
                                            
                                            >Submit</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </>}

                </IonGrid>

                <IonAlert
                    isOpen={alertOpen}
                    onDidDismiss={() => {
                        showAlert(false)
                    }}
                    header={alertTitle}
                    message={alertMessage}
                    buttons={['OK']}
                />
      
            </div>)
        }
            return(<></>)
            
    }

    return (
        displayMain()
    )
}


export default SignupForm