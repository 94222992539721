
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import Bear from './images/Bear.png'
import Chill from './images/Chill.png'
import Success from './images/Success.png'
import { IonButton, IonCheckbox, IonAlert, IonGrid, IonRow, IonCol, IonRadioGroup, IonRadio, IonItem, IonLabel, IonModal } from '@ionic/react';


import "./App.css";

export const MTDOptionsPage2 = (props) => {
    const mtdState = props.location.state ?  props.location.state : {}
    const [ redirect, setRedirect] = useState(false)
    const [ redirectMailingList, setRedirectMailingList] = useState(false)
    const [modalPop, setModalPop] = useState(false)
    const [failModal, setFailModal] = useState(false)
    const [questions, setQuestions] = useState([
        // {
        // title: "Are you currently required to complete a self assessment tax return?",
        // false: "Unfortunately the pilot is only for taxpayers already within self-assessment",
        // true: "",
        // response: ""
        // },
        {

        title: "Are you UK resident?",
        false: "Unfortunately you won't be able to take part in the MTD Income Tax pilot",
        true: "",
        response: ""
        },
        {
   
        title: "Are your tax returns up to date?",
        false: "Unfortunately you won't be able to take part in the MTD Income Tax pilot",
        true: "",
        response: ""
        },
        {

        title: "Did your sole trader business start more than two years ago and/or (if appropriate) has your property business been running for at least one year?",
        false: "Unfortunately you won't be able to take part in the MTD Income Tax pilot",
        true: "",
        response: ""
        },
        {
  
        title: "Have you claimed COVID-19 support funding?",
        false: "",
        true: "Unfortunately you won't be able to take part in the MTD Income Tax pilot",
        response: ""
        },
        {

        title: "Does your business have a 5th April year end?",
        false: "Unfortunately you won't be able to take part in the MTD Income Tax pilot",
        true: "",
        response: ""
        },
        {

        title: "Are you be able to start keeping digital records (for example using untied)?",
        false: "Unfortunately you won't be able to take part in the MTD Income Tax pilot",
        true: "",
        response: ""
        },
        {
        title: "Are you using, or going to start using functional compatible software (like untied) that will allow you to report quarterly to HMRC?",
        false: "Unfortunately you won't be able to take part in the MTD Income Tax pilot",
        true: "",
        response: ""
        },
    ])

    useEffect(()=>{
        let num = questions.length
        if(questions[num-1].response == "Yes"){
            setModalPop(true)
        }
    }, [questions])

    useEffect(()=>{
        if(!modalPop){
        let num = questions.length
        questions[num-1].response = ""
        }
    }, [modalPop])

    const styles = {
        closed:{
            display: "none"
        },
        open:{

        },
        radio:{
        "--color": '#3f90b0',
        "--color-checked" : '#3f90b0'
        },
        checkboxStyle:{
            fontSize: '32px',
            "--border-width": '2px', 
            "--border-color": '#3f90b0', 
            "--background-checked": "#3f90b0",
            marginRight: "10px"
        },
        checkboxStylePadd:{
            paddingLeft: 21,
            fontSize: '32px',
            "--border-width": '2px', 
            "--border-color": '#3f90b0', 
            "--background-checked": "#3f90b0",
            marginRight: "10px"
        },
        checkboxStyleBig:{
            fontSize: '32px',

            "--border-width": '2px', 
            "--border-color": '#3f90b0', 
            "--background-checked": "#3f90b0",
           
        },
        checkboxContainer:{ 
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
            marginRight: '50px' },
        
        checkboxContainerColumn:{
            textAlign: "left",
            display: "flex", 
            flexDirection: 'column',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
            marginRight: '50px' },
        secondaryCheckboxContainer:{ 
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
            marginRight: '50px',
            paddingLeft: "10px", },

        innerCheckboxContainer:{
            paddingLeft: "21px",
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
        },
        innerSecondaryCheckboxContainer:{
            paddingLeft: "100px",
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px',

        },
        infoStyle:{
           
            backgroundColor: "rgba(132,200,217, 0.1)",
            borderRadius: "5px",
            minHeight: "fit-content",
            width: "80%",
            // marginLeft: "75px",
            // marginTop: "10px",
        },
        infoStylePadding:{
            backgroundColor: "rgba(132,200,217, 0.1)",
            borderRadius: "5px",
            minHeight: "fit-content",
            width: "80%",
            marginLeft: "75px",
            marginTop: "10px",
        },
        innerStyle:{
            display: "flex",
            textAlign: "left"
        },
        innerStyleColumn:{
            display: "flex",
            textAlign: "left",
            flexDirection: "column",
        },
        paddingButton: {
            padding: "10px 10px 5px 10px"
        },
        paddingNoButton: {
            padding: "10px 10px 10px 10px"
        }


    }

    const coreModel = (info) => {
      
        return(<>
            <div  >
                <div style={ styles.checkboxContainerColumn }>
                 <div>{info.title}</div>
                 <div>
                 <IonRadioGroup
                    style = {{display: "flex", flexDirection: "row"}} 
                    value={info.response} 
                    onIonChange={e => {
                     info.response = e.detail.value
                     let index = questions.findIndex(q => q.title == info.title)
                     let questionsClone = [...questions]
                     questionsClone[index] = info
                     setQuestions(questionsClone)
                     if(info.title == "Have you claimed COVID-19 support funding?" && e.detail.value == "Yes"){
                         setFailModal(true)
                     }
                    else if (info.title !== "Have you claimed COVID-19 support funding?" && e.detail.value == "No"){
                        setFailModal(true)
                    }
                     }} >

                    <ion-item lines = "none"  className = "short-item">
                        <ion-label>Yes</ion-label>
                        <ion-radio style={styles.radio}  slot="start" value="Yes"></ion-radio>
                    </ion-item>
                

                    <ion-item lines = "none"  className = "short-item">
                        <ion-label>No</ion-label>
                        <ion-radio style={styles.radio}  slot="start" value="No"></ion-radio>
                    </ion-item>
            

                 </IonRadioGroup>
                 {info.response == "Yes" && info.true && <div style = {styles.infoStyle}>
                        <div style = {styles.innerStyle}>
         
                        <div style = {styles.paddingNoButton}>  
                           {info.true}
                        </div>
                    </div>  
                    </div>}


                    {info.response == "No" && info.false && 

                    <div style = {styles.infoStyle}>
                        <div style = {styles.innerStyle}>
         
                        <div style = {styles.paddingNoButton}>  
                            {info.false}
                        </div>
                        </div>  
                    </div>
                    }
                 </div>
                 </div>
            </div>
              
            </>
        )
    }


    const questionsAll = () => {
        return(
            questions.map((q ,i) => {
                if(i == 0 || (questions[i-1].title == "Have you claimed COVID-19 support funding?" && questions[i-1].response == "No")){
                    return(
                        coreModel(q)
                        )
                }
                if (i>0 && questions[i-1].response == "Yes" && questions[i-1].title !== "Have you claimed COVID-19 support funding?"){
                return(
                    coreModel(q)
                    )
                }
                if (i>0 && questions[i-1].response == "No" || (questions[i-1].title == "Have you claimed COVID-19 support funding?" && questions[i-1].response == "Yes")){
                    questions.forEach((q , index)=>{
                        if (index>=i){
                            questions[index].response = ""
                        }
                    })
                }
            
            })
        )
    }
    const failModalPop = () => {

        if (failModal) {
            window._mainOffset = 450
        } else {
            window._mainOffset = 0
        }
        
        return(
            <IonModal
            isOpen={failModal}
            className = "continue-modal"
            onDidDismiss={() => setFailModal(false)} 
            
            >

            <IonGrid style = {{height: "100%"}}>
            <IonRow>
                <IonCol style = {{display: "flex", flexDirection: "row"}}> 
                
                <h2 style={{color: "#3f90b0", textAlign: "center", width: "100%"}}>MTD early adopter pilot</h2>
                {/* <div style={{paddingRight: "10px", marginLeft: "auto", cursor: "pointer", width: "5%", color: "lightgrey"}}>  
                <CloseIcon
                    onClick={() => {setFailModal(false)}}
                />
                </div> */}
                </IonCol>
            </IonRow>
            <IonRow>
            <IonCol style = {{width: "100%", textAlign: "center"}}>
                    <img src={Chill} style ={{height: 200}} />
            </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                <div style = {{margin: "10px", textAlign: "center"}}>
                Looks like you won't be able to take part in the MTD Income Tax pilot just yet. We can keep you updated about any changes.
                </div>
                </IonCol>
            </IonRow>
            <IonRow style = {{marginBottom: 5}}>
            <IonCol style = {{width: "100%", textAlign: "center"}}>
            <IonButton 
                className= "ion-button-inverse" 
                onClick = {()=> setFailModal(false)}
                >Cancel</IonButton>

            </IonCol>
            <IonCol style = {{width: "100%", textAlign: "center"}}>
            <IonButton 
                className= "ion-button-money" 
                // href = "https://www.youtube.com/watch?v=_asNhzXq72w"
                onClick = {()=>{setRedirectMailingList(true)}}
                >Whats next?</IonButton>
            </IonCol>
            </IonRow>
            </IonGrid>
            </IonModal>
            )
    }

    const nowContinueModal = () => {
        return(
            <IonModal
            isOpen={modalPop}
            className = "continue-modal"
            onDidDismiss={() => setModalPop(false)} 
            
            >

        <IonGrid>
            <IonRow>
                <IonCol style = {{display: "flex", flexDirection: "row"}}> 
                
                <h2 style={{color: "#3f90b0", textAlign: "center", width: "100%"}}>MTD early adopter pilot</h2>
                {/* <div style={{paddingRight: "10px", marginLeft: "auto", cursor: "pointer", width: "5%", color: "lightgrey"}}>  
                <CloseIcon
                    onClick={() => {setModalPop(false)}}
                />
                </div> */}
                </IonCol>
            </IonRow>
            <IonRow>
            <IonCol style = {{width: "100%", textAlign: "center"}}>
                    <img src={Success} style ={{height: 200}} />
            </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                <div style = {{margin: "10px", textAlign: "center"}}>
                Congratulations! You meet the requirements to be able to join the MTD Income Tax Pilot.
                </div>
                </IonCol>
            </IonRow>
            <IonRow style = {{marginBottom: 5}}>
            <IonCol style = {{width: "100%", textAlign: "center"}}>
            <IonButton 
                className= "ion-button-inverse" 
    

                onClick = {()=> setModalPop(false)}
                >Cancel</IonButton>

            </IonCol>
            <IonCol style = {{width: "100%", textAlign: "center"}}>
            <IonButton 
                className= "ion-button-money" 
       
        
                onClick = {()=>{setRedirect(true)}}
                >Whats next?</IonButton>
            </IonCol>
            </IonRow>
            </IonGrid>

            </IonModal>
        )
    }



    const displayMain = () => {

        if(redirect){
            return(
                <Redirect
                to={{
                pathname: "/form",
                state: { mtdState: mtdState, questions: questions, form: "success" }
                }}
                />
            )
        }
        if(redirectMailingList){
            return(
                <Redirect
                to={{
                pathname: "/form",
                state: { mtdState: mtdState, questions: questions, form: "mailingList" }
                }}
                />
            )
        }
        else{
            return(
            <div className = "mainDiv">
             
                <h2 style = {{textAlign: "left", color: "#3f90b0"}}>Check if you can take part in the MTD ITSA pilot </h2>
                {/* <div style = {{textAlign: "left"}}>Check if me, or my client, will be within MTD ITSA from April 2024?</div> */}
                {/* <div style = {styles.checkboxContainer}>Tick all options that apply</div> */}
                {questionsAll()}
                {failModalPop()}
                {nowContinueModal()}

            </div>)
        }
    }

    return (
        displayMain()
    )
}


export default MTDOptionsPage2