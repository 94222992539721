
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import Bear from "./images/Bear.png"
import { IonButton, IonCheckbox, IonAlert, IonGrid, IonRow, IonCol, IonRadioGroup, IonRadio, IonItem, IonLabel, IonPopover, IonModal } from '@ionic/react';


import "./App.css";

export const MTDOptions = () => {

    const [selected, setSelected] = useState("")
    const [vatSelected, setVatSelected] = useState("")
    const [turnoverSelected, setTurnoverSelected] = useState("")
    const [cleared, setCleared] = useState(false)
    const [ redirect, setRedirect] = useState(false)
    const [mtdState, setMtdState] = useState({
        company: false,
        vat: false,
        sole: false,
        landlord: false,
        partner: false,
        combo: false,
        none: false,
        LLP: false,
        selfAssess: false, 
        turnover: false,
    })

    const styles = {
        closed:{
            display: "none"
        },
        open:{

        },
        radio:{
        "--color": '#3f90b0',
        "--color-checked" : '#3f90b0'
        },
        checkboxStyle:{
            fontSize: '32px',
            "--border-width": '2px', 
            "--border-color": '#3f90b0', 
            "--background-checked": "#3f90b0",
            marginRight: "10px"
        },
        checkboxStylePadd:{
            paddingLeft: 21,
            fontSize: '32px',
            "--border-width": '2px', 
            "--border-color": '#3f90b0', 
            "--background-checked": "#3f90b0",
            marginRight: "10px"
        },
        checkboxStyleBig:{
            fontSize: '32px',

            "--border-width": '2px', 
            "--border-color": '#3f90b0', 
            "--background-checked": "#3f90b0",
           
        },
        checkboxContainer:{ 
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
            marginRight: '50px' },
        
        checkboxContainerColumn:{
            textAlign: "left",
            display: "flex", 
            flexDirection: 'column',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
            marginRight: '50px' },
        secondaryCheckboxContainer:{ 
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
            marginRight: '50px',
            paddingLeft: "10px", },

        innerCheckboxContainer:{
            paddingLeft: "21px",
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px', 
        },
        innerSecondaryCheckboxContainer:{
            paddingLeft: "100px",
            display: "flex", 
            flexDirection: 'row',
            width: "100%", 
            paddingTop: '10px', 
            marginBottom: '10px',

        },
        infoStyle:{
           
            backgroundColor: "rgba(132,200,217, 0.1)",
            borderRadius: "5px",
            minHeight: "fit-content",
            width: "80%",
            // marginLeft: "75px",
            // marginTop: "10px",
        },
        infoStylePadding:{
            backgroundColor: "rgba(132,200,217, 0.1)",
            borderRadius: "5px",
            minHeight: "fit-content",
            width: "80%",
            marginLeft: "75px",
            marginTop: "10px",
        },
        innerStyle:{
            display: "flex",
            textAlign: "left"
        },
        innerStyleColumn:{
            display: "flex",
            textAlign: "left",
            flexDirection: "column",
        },
        paddingButton: {
            padding: "10px 10px 5px 10px"
        },
        paddingNoButton: {
            padding: "10px 10px 10px 10px"
        }


    }

    const displayVat = () => {
        return(<>
            <div  >
                <div style={ styles.checkboxContainerColumn }>
                 <div>Are you VAT registered?</div>
                 <div>
                 <IonRadioGroup 
                 style = {{display: "flex", flexDirection: "row"}} 
                 value={vatSelected} 
                 onIonChange={e => {
                     setVatSelected(e.detail.value)
                     }} >

                    <ion-item lines = "none"  className = "short-item">
                        <ion-label>Yes</ion-label>
                        <ion-radio style={styles.radio}  slot="start" value="Yes"></ion-radio>
                    </ion-item>
{/* 
                    {selfAssessmentTrue()} */}


                    <ion-item lines = "none"  className = "short-item">
                        <ion-label>No</ion-label>
                        <ion-radio style={styles.radio}  slot="start" value="No"></ion-radio>
                    </ion-item>


                 </IonRadioGroup>
                 </div>
                    {vatTrue()}
                 </div>
            </div>
              
            </>
        )
    }


    const vatTrue = () => {
        if (vatSelected=="Yes"){
            return(
                <div style = {styles.infoStyle}>
                 <div style = {styles.innerStyle}>

                    <div style = {styles.paddingNoButton}>  
                    You won't be within the April 2024 phase of MTD. <br/> VAT registered businesses are working to a different timetable. You need to follow the MTD rules by April 2022.
                    </div>
                </div>  
                </div>
            )
        }
    }

    const displayIncome = () => {
        if (vatSelected=="No" ){
        return(
            <div  style={{paddingBottom:0}}>
            <div style={ styles.checkboxContainer } >Are you  </div>
            <div style = {{fontSize: 15, fontStyle: "italic", alignSelf: "flex-end", textAlign: "left", color: "grey"}} >(select all options that apply)</div>

                <div style={ styles.innerCheckboxContainer }>
                <IonCheckbox
                    style={styles.checkboxStyle}
                    checked={mtdState.sole}
                    onIonChange={(e) => {
                        setMtdState({
                            ...mtdState,
                            sole: e.detail.checked,
                        })
                    }}
                />
                 <div>Self-employed</div>
                 </div>
                 {/* {soleTrue()} */}

                <div style={ styles.innerCheckboxContainer }>
                <IonCheckbox
                    style={styles.checkboxStyle}
                    checked={mtdState.landlord}
                    onIonChange={(e) => {
                        setMtdState({
                            ...mtdState,
                            landlord: e.detail.checked,
                        })
          
                    }}
                />
                 <div> A property landlord</div>
                 </div>
                 {/* {landlordTrue()} */}

                 {/* <div style={ styles.innerCheckboxContainer }>
                <IonCheckbox
                    style={styles.checkboxStyle}
                    checked={mtdState.partner}
                    onIonChange={(e) => {
                        setMtdState({
                            ...mtdState,
                            partner: e.detail.checked,
                        })
                    }}
                />
                 <div>Member of a partnership</div>
                 </div>
                 {partnerTrue()} */}

                 {/* <div style={ styles.innerCheckboxContainer }>
                <IonCheckbox
                    style={styles.checkboxStyle}
                    checked={mtdState.combo}
                    onIonChange={(e) => {
                        setMtdState({
                            ...mtdState,
                            combo: e.detail.checked,
                        })
                    }}
                /> */}
                 {/* <div>a combination of the above</div>
                 </div>
                 {comboTrue()}
                */}
                 <div style={ styles.innerCheckboxContainer }>
                <IonCheckbox
                    style={styles.checkboxStyle}
                    checked={mtdState.none}
                    onIonChange={(e) => {
                        setMtdState({
                            ...mtdState,
                            none: e.detail.checked,
                        })
                    }}
                /> 
                 <div>Neither of the above</div>
                 </div>

                {noneTrue()}
            </div>
        )
    }
    }

    const noneTrue = () => {
        if (mtdState.none){
            return(
                <div style = {styles.infoStylePadding}>
                 <div style = {styles.innerStyle}>

                    <div style = {styles.paddingNoButton}>  
                    You won't be within MTD from April 2024
                    </div>
                </div>  
                </div>
            )
        }
    }

   
    const partnerTrue = () => {
        if (mtdState.partner){
            return(
                <>
                <div style = {styles.infoStylePadding}>
                 <div style = {styles.innerStyle}>

                    <div style={ styles.secondaryCheckboxContainer }>
                <IonCheckbox
                    style={styles.checkboxStyle}
                    checked={mtdState.LLP}
                    onIonChange={(e) => {
                        setMtdState({
                            ...mtdState,
                            LLP: e.detail.checked,
                        })
                    }}
                />
                 <div>Is this an LLP (limited liability partnership) or large partnership, or are any of the partners a company?</div>
                 </div>
                </div>
                </div>
                {LLPTrue()} 
                </> 
            )
        }
    }

    const LLPTrue = () => {
        if (mtdState.LLP){
            return(
                <div style = {styles.infoStylePadding}>
                 <div style = {styles.innerStyle}>

                    {/* <div style = {styles.paddingNoButton}>  You won't be within the April 2024 phase of MTD because these type of partnerships are not expected to be within MTD until sometime after 2024</div> */}
                    <div style = {styles.paddingNoButton}>  
                    Partnerships won't be within MTD until April 2025. So if this is your only source you won't be within MTD for Income Tax from April 2024
                    </div>
                </div>  
                </div>
            )
        }
    }

    const displaySelfAssessment = () => {
        if (vatSelected=="No" && (mtdState.sole || mtdState.landlord || mtdState.partner) && !mtdState.LLP && !mtdState.none){
        return(<>
            <div  >
                <div style={ styles.checkboxContainerColumn }>
                 <div>Are you required to complete a self assessment tax return for the tax year from April 2021 to April 2022?</div>
                 <div>
                 <IonRadioGroup 
                 style = {{display: "flex", flexDirection: "row"}} 
                 value={selected} 
                 onIonChange={e => {
                     setSelected(e.detail.value)
                     if (e.detail.value == "No"){
                        setTurnoverSelected("")
                     }
                     
                     }} >

                    <ion-item lines = "none"  className = "short-item">
                        <ion-label>Yes</ion-label>
                        <ion-radio style={styles.radio}  slot="start" value="Yes"></ion-radio>
                    </ion-item>
{/* 
                    {selfAssessmentTrue()} */}


                    <ion-item lines = "none"  className = "short-item">
                        <ion-label>No</ion-label>
                        <ion-radio style={styles.radio}  slot="start" value="No"></ion-radio>
                    </ion-item>


                 </IonRadioGroup>
                 </div>
                    {assessNo()}
                 </div>
            </div>
              
            </>
        )
        }
    }

    const displayTurnover = () => {
        if (vatSelected=="No" && (mtdState.sole || mtdState.landlord || mtdState.partner) && !mtdState.LLP && !mtdState.none){
        return(<>
            <div  >
                <div style={ styles.checkboxContainerColumn }>
                 <div>Will the total turnover from your businesses be above £10,000?</div>
                 <div>
                 <IonRadioGroup 
                 style = {{display: "flex", flexDirection: "row"}} 
                 value={turnoverSelected} 
                 onIonChange={e => setTurnoverSelected(e.detail.value)} >

                    <ion-item lines = "none" className = "short-item">
                        <ion-label>Yes</ion-label>
                        <ion-radio style={styles.radio}  slot="start" value="Yes"></ion-radio>
                    </ion-item>

                    


                    <ion-item lines = "none"  className = "short-item">
                        <ion-label>No</ion-label>
                        <ion-radio style={styles.radio}  slot="start" value="No"></ion-radio>
                    </ion-item>

                

                </IonRadioGroup>

                 </div>
                 {turnoverTrue()}
                 {turnoverNo()}
                 </div>
            </div>
            </>
        )
            }
    }

    const turnoverTrue = () => {
        if (turnoverSelected == "Yes"){
            return(
                <div style = {styles.infoStyle}>
                 <div style = {styles.innerStyle}>

                    <div style = {styles.paddingNoButton}>  
                    You will be required to follow the MTD rules from April 2024
                    </div>
                </div>  
                </div>
            )
        }
    }
    const turnoverNo = () => {
        if (turnoverSelected == "No"){
            return(
                <div style = {styles.infoStyle}>
                 <div style = {styles.innerStyle}>

                    <div style = {styles.paddingNoButton}>  
                    You won't be within MTD as your turnover is below the threshold
                    </div>
                </div>  
                </div>
            )
        }
    }

    const assessNo = () => {
        if (selected == "No"){
            return(
                <div style = {styles.infoStyle}>
                 <div style = {styles.innerStyle}>

                    <div style = {styles.paddingNoButton}>  
                    You won't be within MTD from April 2024
                    </div>
                </div>  
                </div>
            )
        }
    }

    const nowContinueModal = () => {

        if (turnoverSelected == "Yes") {
            window._mainOffset = 460
        } else {
            window._mainOffset = 0
        }

        return(
            <IonModal
            isOpen={turnoverSelected == "Yes"}
            className = "continue-modal"
            onDidDismiss={() => setTurnoverSelected("")} 
            scrollY = {false}
            
            >
            <IonGrid>
            <IonRow>
                <IonCol style = {{display: "flex", flexDirection: "row"}}> 
                
                {/* <h2 style={{color: "#3f90b0", textAlign: "center", width: "100%"}}>MTD early adopter pilot</h2> */}
                <h2 style={{color: "#3f90b0", textAlign: "center", width: "100%"}}>You will be required to follow the MTD rules from April 2024</h2>
                {/* <div style={{paddingRight: "10px", marginLeft: "auto", cursor: "pointer", width: "5%", color: "lightgrey"}}>  
                <CloseIcon
                    onClick={() => {setTurnoverSelected("")}}
                />
                </div> */}
                </IonCol>
            </IonRow>
            <IonRow>
            <IonCol style = {{width: "100%", textAlign: "center"}}>
                    <img src={Bear} style ={{height: 200}} />
            </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                <div style = {{margin: "10px", textAlign: "center"}}>
                Now continue to see if you can take part in untied's MTD early adopter pilot
                </div>
                </IonCol>
            </IonRow>
            <IonRow style = {{marginBottom: 5}}>
            <IonCol style = {{width: "100%", textAlign: "center"}}>
            <IonButton 
                className= "ion-button-inverse" 
    

                onClick = {()=> setTurnoverSelected("false")}
                >Cancel</IonButton>

            </IonCol>
            <IonCol style = {{width: "100%", textAlign: "center"}}>
            <IonButton 
                className= "ion-button-money" 
       
        
                onClick = {()=>{setRedirect(true)}}
                >Continue</IonButton>
            </IonCol>
            </IonRow>




            </IonGrid>

            </IonModal>
        )
    }




    const displayMain = () => {
        if(redirect){
            return(
                <Redirect
                to={{
                pathname: "/page2",
                state: { mtdState: mtdState }
                }}
                />
            )
        }
        else{
            return(
            <div className = "mainDiv">
             
                <h2 style = {{textAlign: "left", color: "#3f90b0"}}>Will you be within MTD for income tax from April 2024?</h2>
                {/* <div style = {{textAlign: "left"}}>Check if me, or my client, will be within MTD ITSA from April 2024?</div> */}
                {/* <div style = {styles.checkboxContainer}>Tick all options that apply</div> */}
                {/* {displayCompany()} */}
                {displayVat()}
                {displayIncome()}
                {/* {displaySelfAssessment()} */}
                {displayTurnover()}
                {nowContinueModal()}
            </div>)
        }
    }

    return (
        displayMain()
    )
}


export default MTDOptions